import { FC, useState } from 'react'

import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Passage } from '../../../models3/Passage'
import { PassageVideo } from '../../../models3/PassageVideo'
import { Root } from '../../../models3/Root'
import { MimeType } from '../../../types'
import FilePicker from '../../filePickers/FilePicker'
import { ModalFooter } from '../Modals'

interface ImportVerseTimeCodesModalProps {
    passage: Passage
    passageVideo: PassageVideo
    rt: Root
    setIsModalOpen: (isOpen: boolean) => void
}

const ImportVerseTimeCodesModal: FC<ImportVerseTimeCodesModalProps> = ({
    passage,
    passageVideo,
    rt,
    setIsModalOpen
}) => {
    const { t } = useTranslation()
    const [error, setError] = useState<string>()
    const [file, setFile] = useState<File>()

    const isStringConvertibleToNumber = (str: string) => !isNaN(Number(str))

    const isValidTabDelimited = (fileLines: string[]) => {
        for (const line of fileLines) {
            const values = line.split('\t')
            if (values.length !== 3) {
                return false
            }

            const [start, end, reference] = values
            if (
                !isStringConvertibleToNumber(start) ||
                !isStringConvertibleToNumber(end) ||
                !rt.parseReferences(reference, false)
            ) {
                return false
            }
        }
        return true
    }

    const onConfirm = async () => {
        if (!file) {
            return
        }

        try {
            const fileContent = await file.text()
            const fileLines = fileContent.split('\n')

            if (isValidTabDelimited(fileLines)) {
                await passageVideo.removeAllReferences(passage)
                for (const line of fileLines) {
                    const values = line.split('\t')
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [start, _, reference] = values
                    const startNumber = Number(start)
                    const references = rt.parseReferences(reference, false)

                    await passageVideo.addReference(references, startNumber)
                }
                setIsModalOpen(false)
            } else {
                setError(t('invalidImportVerseTimeCodeFileErrorMessage'))
            }
        } catch (err) {
            setError(t('invalidImportVerseTimeCodeFileErrorMessage'))
            console.error(err)
        }
    }

    return (
        <Modal backdrop="static" onHide={() => setIsModalOpen(false)} show>
            <Modal.Header closeButton>
                <Modal.Title>{t('importVerseTimeCodes')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-file-picker">
                    <FilePicker
                        accept={MimeType.TXT}
                        className="modal-file-picker-icon"
                        enabled
                        setSelectedFiles={(fileList) => {
                            if (fileList.length > 0) {
                                const singleFile = fileList[0]
                                setFile(singleFile)
                            }
                        }}
                    />
                    {t('chooseFile')}
                </div>
                {file && <div>{file.name}</div>}
                {error && <div className="text-danger">{error}</div>}
            </Modal.Body>
            <ModalFooter enabledOK={Boolean(file)} onCancel={() => setIsModalOpen(false)} onOK={onConfirm} />
        </Modal>
    )
}

export default ImportVerseTimeCodesModal
